/* Categories Grid */
.categories-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.categories-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s;
  background-color: #fa324d;
}

.categories-card img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.categories-card h3 {
  margin: 8px 0;
  padding: 0 8px;
}

.categories-card:hover {
  transform: scale(1.05);
}
