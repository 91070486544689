/* index.css */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap");

:root {
  --primary: #fa324d;
  --secondary: #0e0e16;
}

h1,
h2,
h3,
p {
  color: #fefcfb;
  text-align: center;
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--secondary);
  color: #d81414;
  display: flex;
  flex-direction: column;
}

/* Wrapper container for the entire page */
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full height of the viewport */
}

/* Flex-grow the main content to push the footer down */
.main-content {
  flex-grow: 1;
}

/* Footer styles */
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background-color: #10b0e6;
  color: #0e0e16;
  width: 100%;
  box-sizing: border-box;
}

.footer-content {
  display: flex;
  align-items: center;
  width: 100%;
}

.footer-links {
  display: flex;
  align-items: center;
}

.footer-links a {
  color: white;
  text-decoration: none;
  font-size: 14px;
}

.footer-links a:hover {
  text-decoration: underline;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logo {
  filter: invert(100%) brightness(200%);
}

nav {
  background: var(--primary);
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-links {
  display: flex;
  align-items: center;
}

.navbar-brand {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.navbar-brand img {
  margin-right: -5px;
}

.navbar-brand h1 {
  margin: 0;
  font-size: 1.5em;
  color: #fefcfb;
}

.nav-links a {
  color: #fefcfb;
  margin: 0 10px;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 4px;
  transition: background 0.2s;
}

.nav-links a:hover {
  background: rgba(255, 255, 255, 0.1);
}

.auth-buttons {
  display: flex;
  gap: 10px;
}

.auth-buttons a {
  color: #fefcfb;
  text-decoration: none;
  padding: 10px 20px;
  background: var(--secondary);
  border-radius: 4px;
  transition: background 0.2s;
}

.auth-buttons a:hover {
  background: #10b0e6;
}

.page {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  padding-bottom: 80px;
}

/* High Score Section */
.highScore {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.highScore h2 {
  margin-bottom: 20px;
}

.highScore-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* Categories Grid */
.categories-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.categories-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s;
  background-color: #001f54;
}

.categories-card img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.categories-card h3 {
  margin: 8px 0;
  padding: 0 8px;
}

.categories-card:hover {
  transform: scale(1.05);
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7); /* Grey out the background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it is above other content */
}

.modal-content {
  background: var(--secondary);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
}

.options button {
  display: block;
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  background: var(--primary);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.options button:hover {
  background: #1282a2;
}

.timer {
  margin: 20px 0;
  font-size: 18px;
  font-weight: bold;
}

/* Form Styles */
.form-container {
  background: var(--primary);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 20px auto;
  color: #fefcfb;
}

.form-container h2 {
  margin-bottom: 20px;
}

.form-container input {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px 0;
  border: none;
  border-radius: 4px;
}

.form-container button {
  padding: 10px 20px;
  background: var(--secondary);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.form-container button:hover {
  background: #1282a2;
}

.form-message {
  margin-top: 10px;
}
