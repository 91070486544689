/* QuestionModal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7); /* Grey out the background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it is above other content */
}

.modal-content {
  background: #0e0e16;
  padding: 40px 20px 20px 20px; /* Add padding at the top for the exit button */
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  position: relative;
}

.exit-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: #434344;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
}

.exit-button:hover {
  background: #10b0e6;
}

.options {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin: 20px 0;
}

.options button {
  padding: 10px;
  background: #717184; /* Default background color for options */
  color: white; /* Default text color for options */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.options button:hover {
  background: #fa324d; /* Hover background color */
}

.options button.selected {
  background: #d81414; /* Background color for selected option */
  border: 2px solid #fff; /* Border color for selected option */
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.modal-buttons button {
  padding: 10px 20px;
  background: #434344;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.modal-buttons button:hover {
  background: #10b0e6;
}

/* Feedback message styles */
.feedback-message {
  margin-top: 20px;
  font-size: 22px;
  font-weight: bold;
}

.feedback-message.correct {
  color: rgb(112, 255, 112);
}

.feedback-message.incorrect {
  color: rgb(255, 173, 20);
}
