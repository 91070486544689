/* App.css */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #001f54;
  color: white;
}

.nav-links {
  display: flex;
  align-items: center;
}

.nav-links a {
  padding: 10px 20px;
  color: white;
  text-decoration: none;
  margin-right: 15px;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.3s;
}

.nav-links a:hover {
  text-decoration: underline;
}

.auth-buttons {
  display: flex;
  align-items: center;
}

.auth-buttons a {
  color: white;
  text-decoration: none;
  margin-right: 15px;
  font-size: 16px;
}

.auth-buttons a:hover {
  transform: scale(1.05);
}

.logout-button {
  padding: 10px 20px;
  background-color: #0e0e16; /* Button background color */
  color: white; /* Text color */
  border: none; /* Remove border */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  margin-right: 15px;
  font-size: 16px; /* Font size */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transition for background color and transform */
}

.logout-button:hover {
  background-color: #00c8ff; /* Background color on hover */
  transform: scale(1.05); /* Slightly enlarge the button on hover */
}

.logout-button:active {
  background-color: #1e352f; /* Background color when the button is clicked */
}

/* Add this to App.css or Home.css */
.score-container {
  text-align: center;
  margin-bottom: 20px;
}

.score-container h2 {
  font-size: 2em;
  margin: 0;
  color: white;
}

.score-container h2:last-of-type {
  margin-top: 10px;
  color: #00c8ff;
}

.start-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Default height */
  transition: height 0.3s;
}

.start-button-container.small {
  height: 20px; /* Smaller height when the game starts */
}

.start-game-button {
  padding: 15px 20px;
  background-color: #d81414;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 32px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.start-game-button:hover {
  background-color: #10b0e6;
  transform: scale(1.05);
}

.start-game-button:disabled {
  background-color: #007bff88;
  cursor: not-allowed;
}
