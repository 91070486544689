/* Login.css and Register.css */
.form-container {
  width: 100%;
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.form-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.form-container form {
  display: flex;
  flex-direction: column;
}

.form-container input {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.form-container .password-container {
  position: relative;
  display: flex;
  align-items: center;
}

.form-container .password-container input {
  flex: 1;
}

.form-container .password-toggle {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.form-container button {
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #0e0e16;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.form-container button:disabled {
  background-color: #10b0e6;
  cursor: not-allowed;
}

.form-container .form-message {
  text-align: center;
  margin-top: 10px;
  color: #fa324d;
}
