/* HighScoreCard.css */
.high-score-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the items horizontally */
  margin-top: 20px;
}

.high-score-title-box {
  width: 100%;
  max-width: 300px;
  padding: 20px;
  background: linear-gradient(145deg, #10b0e6, #10b0e6);
  color: #fefcfb;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  font-family: "VT323", monospace;
  text-align: center;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.high-score-title-box h2 {
  margin: 0;
  font-size: 2em;
}

.high-score-card {
  width: 100%;
  max-width: 300px;
  padding: 20px;
  background: linear-gradient(145deg, #dddddd, #c2c2c2);
  color: #fefcfb;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  font-family: "VT323", monospace;
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.high-score-card:hover {
  transform: scale(1.05);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.4);
}

.high-score-card h3 {
  font-size: 2em;
  margin: 0;
  color: #0e0e16;
}

.high-score-card .score {
  background: #f2f2f2;
  color: #0e0e16;
  border-radius: 6px;
  padding: 10px 20px;
  font-size: 1.8em;
  margin-left: 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}
